.account-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #CDFFCD;
  border-radius: 30px;
  padding: 10px 10px  10px  30px;
  width:auto;
  max-width: 600px;
  gap: 37px;
}

.account-info {
  display: flex;
  align-items: center;

}

.active-dot {
  width: 10px;
  height: 10px;
  background-color: #007F00;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.account-text {
  font-weight: bold;
  color: #007F00;
  font-size: 16px;
  font-family:" Dm sans", sans-serif; 
}

.switch-account-btn {
  background-color: #007F00;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 12px;
  font-family: "DM Sans", sans-serif;
}

.switch-account-btn:hover {
  opacity: 0.9;
}
