// 
// authentication.scss
//

.auth-bg{
    background-image: url("../../../images/bg-auth.png");
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    
    @media (min-width: 768px){
        height: 100vh;
    }
}

.auth-full-page-content{
    min-height: 100vh;
}

.auth-bg-login {
    background-image: url("../../../images/bg-auth-login.png");
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
  
    .bg-overlay {
      opacity: 0.9;
    }
  
    /* For small screens (e.g. phones) */
    @media (max-width: 576px) {
      background-size: contain; /* Contain image for smaller screens */
      background-position: center;
      height: 60vh; /* Adjust the height to prevent the image from getting cut off */
    }
  
    /* For tablets and medium screens */
    @media (min-width: 577px) and (max-width: 991px) {
      background-size: cover; /* Ensure the image covers the full background */
      background-position: center;
      height: 80vh; /* Adjust height for medium screens */
    }
  
    /* For desktop screens */
    @media (min-width: 992px) {
      background-size: left;
      background-position: top; /* Align the background image to the top */
      height: 100vh; /* Full screen height for large screens */
    }
  }
  

.bg-resetpassword{
    background-image: url("../../../images/bg-resetpassword.png");
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    .bg-overlay{
        opacity: 0.9;
    }
    @media (min-width: 768px){
        height: 100vh;
    }
}

// auth 2

.auth-logo{
    .logo-txt{
        color: $dark;
        font-size: 20px;
    }
}

// auth-pass-inputgroup

.auth-pass-inputgroup{
    input[type="input"] + .btn .mdi-eye-outline{
        &:before{
            content: "\F06D1";
        }
    }
}


// for bubble effect
.bg-bubbles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    li {
        position: absolute;
        list-style: none;
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 30px;
        background-color: rgba(255, 255, 255, 0.1);
        bottom: -50px;
        animation: square 20s infinite;
        transition-timing-function: linear;
        &:nth-child(1) {
            left: 10%;
        }
        &:nth-child(2) {
            left: 20%;
            width: 120px;
            height: 120px;
            animation-delay: 2s;
            animation-duration: 17s;
        }
        &:nth-child(3) {
            left: 25%;
            animation-delay: 4s;
        }
        &:nth-child(4) {
            left: 40%;
            width: 80px;
            height: 80px;
            animation-duration: 22s;
        }
        &:nth-child(5) {
            left: 70%;
            width: 90px;
            height: 90px;
        }
        &:nth-child(6) {
            left: 70%;
            width: 120px;
            height: 120px;
            animation-delay: 3s;
        }
        &:nth-child(7) {
            left: 32%;
            width: 150px;
            height: 150px;
            animation-delay: 7s;
        }
        &:nth-child(8) {
            left: 55%;
            width: 80px;
            height: 80px;
            animation-delay: 15s;
            animation-duration: 40s;
        }
        &:nth-child(9) {
            left: 25%;
            width: 50px;
            height: 50px;
            animation-delay: 2s;
            animation-duration: 40s;
        }
        &:nth-child(10) {
            left: 90%;
            width: 140px;
            height: 140px;
            animation-delay: 11s;
        }
    }
}

@keyframes square {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-1000px) rotate(600deg);
    }
}

body[data-layout-mode="dark"] {
    .auth-logo{
        .logo-txt{
            color: $white;
        }
    }
}


////////////// responsive image

/* Default size for larger screens (higher height) */
.robot-image {
    width: 24vw;
    max-width: 600px;
    height: auto;
    object-fit: contain;
  }
  
  /* For medium screen heights (900px or less) */
  @media (max-height: 900px) {
    .robot-image {
      width: 28vw; /* Increase width as height decreases */
      max-width: 500px;
    }
  }
  
  /* For smaller screen heights (700px or less) */
  @media (max-height: 700px) {
    .robot-image {
      width: 35vw;
      max-width: 400px;
    }
  }
  
  /* For very small screen heights (500px or less) */
  @media (max-height: 500px) {
    .robot-image {
      width: 45vw;
      max-width: 300px;
    }
  }
  

  /////// responsive logo
  
  /* Default size for large screens (higher height) */
.responsive-logo {
    width: 15vw;  /* Use 15% of the viewport width */
    max-width: 220px; /* Set a maximum width */
    max-height: 36px; /* Set a maximum height */
    height: auto;
  }
  
  /* Adjustments for medium screen heights (900px or less) */
  @media (max-height: 900px) {
    .responsive-logo {
      width: 18vw;
      max-width: 200px;
      max-height: 34px;
    }
  
    /* Adjust margin */
    .position-absolute {
      margin-right: 4vw;
      margin-top: 2vh;
    }
  }
  
  /* Adjustments for smaller screen heights (700px or less) */
  @media (max-height: 700px) {
    .responsive-logo {
      width: 22vw;
      max-width: 180px;
      max-height: 32px;
    }
  
    .position-absolute {
      margin-right: 3vw;
      margin-top: 1.5vh;
    }
  }
  
  /* Further adjustments for very small screen heights (500px or less) */
  @media (max-height: 500px) {
    .responsive-logo {
      width: 25vw;
      max-width: 160px;
      max-height: 28px;
    }
  
    .position-absolute {
      margin-right: 2vw;
      margin-top: 1vh;
    }
  }
  
/* Mobile and small screens */
@media (max-height: 600px) {
    .login-title {
      font-size: 1rem; /* Adjust title size for small screens */
    }
  
    .auth-full-page-content {
      padding: 10px; /* Reduce padding on small screens */
    }
  
    .form-control {
      height: 40px; /* Adjust height for form fields */
      font-size: 0.875rem; /* Reduce font size */
    }
  
    .btn-auth {
      font-size: 0.875rem;
      padding: 12px 0; /* Adjust button padding */
    }
  
    .google-login-button {
      height: 50px; /* Smaller height for Google login button */
    }
  
    /* Adjust text alignment and spacing */
    .auth-content {
      text-align: left;
    }
  }
  
  /* Tablets */
  @media (max-width: 1024px) {
    .login-title {
      font-size: 1.5rem; /* Adjust title size */
    }
  
    .form-control {
      height: 55px; /* Slightly smaller height */
    }
  
    .btn-auth {
      font-size: 1rem;
    }
  
    .google-login-button {
      height: 55px; /* Adjust height */
    }
  
    .auth-full-page-content {
      padding: 15px;
    }
  }
  
  /* Desktops and larger screens */
  @media (min-width: 1200px) {
    .auth-full-page-content {
      padding: 30px;
    }
  
    .btn-auth {
      font-size: 1.125rem;
    }
  }
  
  

///////////// responsive footer

  /* Default Footer Styles */
  .auth-footer {
    font-size: 1rem;  /* Adjust font size */
    padding-bottom: 1rem;
    position: absolute;  /* Fix the footer in the bottom of the page */
    bottom: 0;           /* Align it at the very bottom */
    left: 0;
    right: 0;
    background-color: transparent;
    text-align: center;
  }
  
  
  /* Adjustments for smaller screen heights */
  @media (max-height: 800px) {
    .auth-footer {
      font-size: 0.875rem; /* Make the text smaller on shorter screens */
      padding-bottom: 0.75rem;
      margin-top: 0; /* Remove margin on top */
    }
  }
  
  @media (max-height: 700px) {
    .auth-footer {
      font-size: 0.75rem;
      padding-bottom: 0.5rem;
    }
  }
  
  /* Adjustments for large zoom (150% and higher) */
  @media (min-height: 1200px) {
    .auth-footer {
      font-size: 1.25rem;
      padding-bottom: 1.5rem;
    }
  }
  