.custom-border-card {
  margin: 80px 50px ;
  position: relative;
  border: 2px solid #dcdada; /* Border around the card */
  border-radius: 8px; /* Rounded corners */
  padding-top: 20px; /* Space for the title */
}
.custom-title {
  position: absolute;
  top: -16px; /* Positioning the title on the border */
  left: 10%;
  transform: translateX(-50%); /* Center the title horizontally */
  background: white; /* Match the card's background */
  padding: 0 10px;
  font-size: 1.1rem;
  font-weight: 600; /* Adjust the font size as needed */
  color: #000000; /* Title color */
}

.custom-border-card:before,
.custom-border-card:after {
  content: '';
  position: absolute;
  top: 0;
  width: calc(10% - 60px); /* Adjust this to make space for the title */
  height: 1px;
}

.custom-border-card:before {
  left: 0;
}

.custom-border-card:after {
  right: 0;
}

.custom-card-content {
  padding: 20px; /* Adjust the padding for your content */
}
