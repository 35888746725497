// 
// _card.scss
// 

.card {
    // margin-bottom: $grid-gutter-width;
}

.card-drop {
    color: $body-color;
}

.card-title {
    // font-size: 15.4px;
    margin: 0 0 4px 0;
}

.card-title-desc {
    color: $card-title-desc;
    margin-bottom: 0;
    font-size: 13px;
}

.card-body {
    //ox-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    position: relative;
  }
  .tamour .card-body{
   
    background-color: #F9F1F3;
  }
  
.card-header-tabs {
    margin-top: -$card-cap-padding-y;
}

.card-header-pills {
    margin: calc(-1 * $card-cap-padding-y / 2);
}

body[data-layout-mode="dark"] {
    .card, .card-header,
    .modal-content, .offcanvas {
        background-color: $gray-dark-200;
        border-color: lighten($gray-dark-200,4%);
    }

    .card-title-desc{
        color: $gray-dark-400;
    }
    table.table thead {
        color:white;
    }
}